import { FC } from 'react';

import { AssetTableRow, AssetsTableHeaders } from './components';

import { useAssetsContext } from 'context/AssetsContext/AssetsContext';
import { IAsset } from 'types/interfaces';

export interface Header {
  label: string;
  key: string;
}

interface Props {
  headers: Header[];
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
}

export const AssetManagementTable: FC<Props> = ({ headers = [], assets = [], checkAsset }) => {
  const { getCentralizedRepoForSCMVendor } = useAssetsContext();
  return (
    <div>
      <AssetsTableHeaders headers={headers} />

      {assets.map((asset) => (
        <AssetTableRow
          key={asset.asset_id}
          asset={asset}
          checkAsset={checkAsset}
          headers={headers}
          isDisabled={asset.asset_id === getCentralizedRepoForSCMVendor(asset.vendor)?.asset_id}
        />
      ))}
    </div>
  );
};
