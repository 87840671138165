import { FC } from 'react';

import styles from './PriorityFactorsCell.module.scss';

import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { ITableCell, IAsset } from 'types/interfaces';

export const PriorityFactorsCell: FC<ITableCell<IAsset>> = ({ cell: { value } }) => {
  const factors = value as string[] | undefined;

  return (
    <div className={styles.wrapper}>
      {factors?.map((factor) => (
        <PriorityFactorsBox
          key={factor}
          factors={[{
            key: factor,
            displayName: factor,
          }]}
        />
      ))}
    </div>
  );
};
