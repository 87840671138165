import { FC } from 'react';

import styles from './ResourcePriorityFactorsList.module.scss';

import { PriorityFactorsBox } from 'components/PriorityFactorsBox/PriorityFactorsBox';
import { IAsset } from 'types/interfaces';

interface Props {
  asset?: IAsset;
}

export const ResourcePriorityFactorsList: FC<Props> = ({ asset }) => (
  <div className={styles.wrapper}>
    {asset?.priority_factors?.map((factor) => (
      <PriorityFactorsBox
        key={factor}
        factors={[{
          key: factor,
          displayName: factor,
        }]}
      />
    ))}
  </div>
);
