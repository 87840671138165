import { Paywall } from '@stigg/react-sdk';
import { FC } from 'react';

import { config } from 'config';
import { useStiggExtendedContext } from 'context/StiggContext';

export enum StiggPlanId {
  PLAN_JIT_FREE = 'plan-jit-free',
  PLAN_JIT_GROWTH = 'plan-jit-growth',
  PLAN_JIT_ENTERPRISE = 'plan-jit-enterprise',
}

export const StiggPaywall: FC = () => {
  const { setShowStiggPayWall } = useStiggExtendedContext();
  return (
    <Paywall
      onPlanSelected={({ plan }) => {
        switch (plan.id) {
          case StiggPlanId.PLAN_JIT_FREE:
            setShowStiggPayWall(false);
            break;
          case StiggPlanId.PLAN_JIT_GROWTH:
          case StiggPlanId.PLAN_JIT_ENTERPRISE:
            window.open(config.contactUsUrl, '_blank');
            break;
          default:
            console.error('Unknown plan', plan);
            break;
        }
      }}
    />
  );
};
