import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { TaskDialog } from '../TaskDialog/TaskDialog';
import { useGetChannelsMenuItems } from '../useGetMenuItems/useGetChannelsMenuItems';
import { useGetReposMenuItems } from '../useGetMenuItems/useGetReposMenuItems';

import styles from './BaseTaskDialog.module.scss';

import { ActivateIllustration, ExploreIllustration, PrEnablementIllustration, SlackIllustration } from 'assets';
import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitDropdownNew } from 'components/JitDropdownNew/JitDropdownNew';
import { JitGradientButton } from 'components/JitGradientButton/JitGradientButton';
import { MenuItemType } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useTeamsService } from 'services/TeamsService/useTeamsService';
import colors from 'themes/colors.module.scss';
import { MenuItemKeyType } from 'types/interfaces';
import { ITeamCheckDetails, TaskKeyEnum } from 'types/interfaces/Teams/ITeam';

interface Props {
  isOpen: boolean;
  check: ITeamCheckDetails;
  handleClose: () => void;
  teamId: string;
  updateCheck: (check: ITeamCheckDetails, value?: string) => void;
}

export interface MenuItemsData {
  menuItems: Partial<MenuItemType>[]
  isLoading: boolean;
}

type MenuItemsType = (selectedItem: MenuItemKeyType) => MenuItemsData;

export const BaseTaskDialog: FC<Props> = ({ isOpen, check, handleClose, teamId, updateCheck }) => {
  const [selectedItem, setSelectedItem] = useState<MenuItemKeyType>(check?.value || '');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { updateTeamChecks } = useTeamsService();
  useEffect(() => {
    setIsDisabled(!selectedItem);
  }, [selectedItem]);

  const getUpdateBodyForTask = useCallback(() => [{
    check_slug: check?.checkSlug,
    value: selectedItem as string,
    is_completed: true,
  }], [check?.checkSlug, selectedItem]);

  const handleClick = useCallback(async () => {
    const postUpdateCheckCallbacks: { [checkSlug: string]: (updatedCheck: ITeamCheckDetails) => void } = {
      [TaskKeyEnum.exploreDeveloper]: (updatedCheck: ITeamCheckDetails) => {
        if (updatedCheck.metadata?.url) window.open(updatedCheck.metadata?.url, '_blank');
      },
    };

    setIsLoading(true);
    const result = await updateTeamChecks(teamId, getUpdateBodyForTask());
    result?.forEach((updatedCheck) => {
      updateCheck(updatedCheck, selectedItem as string);

      if (updatedCheck && postUpdateCheckCallbacks[updatedCheck.checkSlug]) {
        postUpdateCheckCallbacks[updatedCheck.checkSlug](updatedCheck);
      }
    });
    setIsLoading(false);
    handleClose();
  }, [updateTeamChecks, teamId, getUpdateBodyForTask, handleClose, updateCheck, selectedItem]);

  const keyToMenuItems: Partial<Record<TaskKeyEnum, { data: MenuItemsType, type: 'autoComplete' | 'dropdown' }>> = useMemo(() => ({
    [TaskKeyEnum.configureSlack]: {
      data: useGetChannelsMenuItems,
      type: 'autoComplete',
    },
    [TaskKeyEnum.exploreDeveloper]: {
      data: useGetReposMenuItems,
      type: 'dropdown',
    },
  }), []);

  const onMenuItemClick = useCallback(({ itemKey }: { itemKey: MenuItemKeyType }) => {
    setSelectedItem(itemKey);
  }, []);

  const keyToMenuItemsData = keyToMenuItems[check.checkSlug]?.data;
  const { menuItems, isLoading: isMenuLoading } = keyToMenuItemsData ? keyToMenuItemsData(selectedItem) : { menuItems: [],
    isLoading: false };
  const fields = (check.isCompleted && check?.options?.fields ? check.options.fields.configure : check.options?.fields?.activate)
    || check.options?.fields?.activate;

  const menuComponent = useMemo(() => {
    if (keyToMenuItems[check.checkSlug]?.type === 'autoComplete') {
      return (
        <JitAutoCompleteForm
          disabled={isMenuLoading}
          getOptionLabel={(option) => option}
          isLoading={isMenuLoading}
          isSingleValue
          options={menuItems.map((item) => item.itemKey)}
          placeHolder={fields?.select?.placeholder}
          selectedValue={selectedItem}
          setSelectedValue={(itemKey) => setSelectedItem(itemKey || '')}
        />
      );
    }
    if (keyToMenuItems[check.checkSlug]?.type === 'dropdown') {
      return (
        <JitDropdownNew
          borderColor={colors.darkGray03}
          defaultDisplayText={fields?.select?.placeholder}
          displayText={selectedItem as string}
          height={40}
          isLoading={isMenuLoading}
          menuItems={menuItems}
          onMenuItemClick={onMenuItemClick}
          padding={20}
        />
      );
    }
    return null;
  }, [check.checkSlug, fields?.select?.placeholder, isMenuLoading, keyToMenuItems, menuItems, onMenuItemClick, selectedItem]);

  if (!fields) return null;

  const checkKeyToIcon = {
    [TaskKeyEnum.prCheckEnablement]: PrEnablementIllustration,
    [TaskKeyEnum.configureSlack]: check.isCompleted ? SlackIllustration : ActivateIllustration,
    [TaskKeyEnum.exploreDeveloper]: ExploreIllustration,
  };

  const left = (
    <div className={styles.left}>
      <div>
        <JitText bold size='l' text={fields.title} />

        <JitText color={colors.lightGray} size='m' text={fields.description} />
      </div>

      {menuComponent}

      <JitGradientButton
        data-testid={`action-${TaskKeyEnum.exploreDeveloper}`}
        disabled={isDisabled && !!menuComponent}
        isLoading={isLoading}
        onClick={handleClick}
      >
        <div className={styles.button}>
          <JitText text={fields?.button?.label} />
        </div>
      </JitGradientButton>
    </div>
  );

  const right = checkKeyToIcon?.[check?.checkSlug as keyof typeof checkKeyToIcon] ? (
    <img
      alt='illustration'
      data-testid='dialog-illustration'
      src={checkKeyToIcon?.[check?.checkSlug as keyof typeof checkKeyToIcon]}
    />
  ) : <div />;

  return (
    <TaskDialog handleClose={handleClose} isOpen={isOpen} leftComponent={left} rightComponent={right} />
  );
};
